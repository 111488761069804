@charset "UTF-8";
i[data-v-10c0009c] {
  margin-left: 4px;
}
.saveAll[data-v-10c0009c] {
  text-align: right;
  margin-top: 15px;
}
.zcgzxc[data-v-10c0009c] {
  margin-top: 10px;
}
.zcgzxc th[data-v-10c0009c] {
  color: rgb(51, 51, 51);
  font-size: 14px;
}
table[data-v-10c0009c] {
  table-layout: fixed;
}
.content[data-v-10c0009c] {
  border: 1px solid #c2c2c2 !important;
  border-bottom: none;
  border-right: none;
  border-collapse: collapse;
}
.content .ss[data-v-10c0009c] {
  position: sticky;
  left: 0; /* 首行永远固定在左侧 */
  z-index: 1;
}
.content .fixed_td[data-v-10c0009c] {
  position: relative;
}
.content .fixed_td td[data-v-10c0009c]:nth-child(3) {
  position: sticky;
  left: 0; /* 首行永远固定在左侧 */
  z-index: 1;
  border-left: 1px solid #c2c2c2;
}
.content .fixed_td td[data-v-10c0009c]:nth-child(3):after {
  width: 1px;
  background: #c2c2c2;
  content: " ";
  clear: both;
  height: 100%;
  position: absolute;
  right: -1px;
  top: 0;
}
.content .fixed_td td[data-v-10c0009c]:nth-child(3):before {
  width: 1px;
  background: #c2c2c2;
  content: " ";
  clear: both;
  height: 100%;
  position: absolute;
  left: -1px;
  top: 0;
}
.content span[data-v-10c0009c] {
  line-height: 34px;
  font-size: 14px;
}
.content tr[data-v-10c0009c] {
  padding: 0;
  margin: 0;
  background: #fff;
}
.content td[data-v-10c0009c] {
  border-right: 1px solid #c2c2c2 !important;
  border-bottom: 1px solid #c2c2c2 !important;
  line-height: 25px;
  padding: 0 2px;
  color: #666;
  font-size: 14px;
  background: #fff;
}
.content input[data-v-10c0009c] {
  width: 90%;
  line-height: 34px;
  padding: 6px 0 6px 6px;
  font-size: 14px;
}
i[data-v-10c0009c] {
  line-height: 8px;
  text-align: center;
}
.icon-baocun[data-v-10c0009c], .icon-shanchu[data-v-10c0009c] {
  color: var(--themeColor, #17a2b8);
  font-size: 18px;
  padding-right: 5px;
}
.top_bg td[data-v-10c0009c] {
  text-align: center;
}
.top_bg .icon-tongbu1[data-v-10c0009c] {
  display: inline-block;
  font-size: 16px;
  color: #67c23a;
  line-height: 26px;
  transform: translateY(2px);
}
.center[data-v-10c0009c] {
  text-align: center;
}