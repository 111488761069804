.el-icon-document[data-v-10c0009c], .iconfont icon-bianji[data-v-10c0009c], .iconfont icon-shanchu[data-v-10c0009c] {
  margin: 0 5px;
  cursor: pointer;
  color: var(--themeColor, #17a2b8);
  font-size: 18px;
  line-height: 24px;
}
.title[data-v-10c0009c] {
  background-color: #fef6ed;
  font-size: 13px;
  padding: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: #333;
  border: 1px solid #ffe1b7;
  border-radius: 3px;
}
[data-v-10c0009c] .el-radio {
  height: 50px;
  align-items: flex-start;
}
[data-v-10c0009c] .el-radio__inner {
  margin-top: 3px;
}